import React from 'react'
import { FreeMode } from 'swiper'
import {Swiper,SwiperSlide} from 'swiper/react'
import "swiper/css";
import "swiper/css/free-mode"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/css/bundle'
import sanityClient from '../lib/client'
import {useEffect,useState} from 'react'
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';



function Newproduct() {
  const {t}=useTranslation()
    const [producte,setProducte]=useState([]);
  useEffect(()=>{
    sanityClient.fetch(
      `*[_type == "newproduct"]{
        image{
          asset ->{
            _id,
            url
          }
        },
        name,
        _id,
        Price,
      }`
    ).then((data)=> setProducte(data)).catch(err=>console.log(err))
  },[])
  return (
    <>

    <h2 className='text-center my-10'>{t("Nouveaux Produits")} </h2>
    <SwiperSlide className='newproduct'>
    <SwiperSlide className='container py-4 px-4 justify-content-center bg-darker my-10'>
  <Swiper
        freeMode={true}
        grabCursor={true}
        modules={[FreeMode]}
        className='mySwiper'
        breakpoints={{
            0:{
                slidesPerView:1,
                spaceBetween:10,
            },
            480:{
                slidesPerView:2,
                spaceBetween:10,
            },
            800:{
                slidesPerView:3,
                spaceBetween:15,
            },
           
            1024:{
                slidesPerView:4,
                spaceBetween:30,
            },
            1280:{
                slidesPerView:4,
                spaceBetween:30,
            },
            1600:{
                slidesPerView:5,
                spaceBetween:30,
            }


        }
}
>
    {producte.map(p=>(

            <SwiperSlide key={p._id}>
                <Card style={{ width: '15rem' }}  className="productcard">
      <Card.Img variant="top" src={p.image.asset.url} style={{height:'158.66px'}}/>
      <Card.Body>
        <Card.Title className='cardtitle'>{p.name}</Card.Title>
        <Card.Text>
          
        </Card.Text>
      </Card.Body>
    </Card>
            </SwiperSlide>
    ))}
            
        </Swiper>
    </SwiperSlide>
    </SwiperSlide>
    </>
  )
}

export default Newproduct