import React from 'react'
import offer1 from '../img/service/IMG_1.jpg'
import offer2 from '../img/service/IMG_2.jpg'
import offer3 from '../img/service/IMG_3.jpg'
import {useTranslation} from 'react-i18next'

function Offer() {
	const {t}=useTranslation()
  return (
    <div>
<section className="offer_area p_120">
        	<div className="container">
        		
        		<div className="row offer_inner">
        			<div className="col-lg-4">
        				<div className="offer_item">
        					<img className="img-fluid" src={offer1} alt=""/>
        					<div className="offer_text">
        						<h4>{t('Personel de qualité pour vous acceuillir')}</h4>
        						
        					</div>
        				</div>
        			</div>
        			<div className="col-lg-4">
        				<div className="offer_item">
        					<img className="img-fluid" src={offer2} alt=""/>
        					<div className="offer_text">
        						<h4> {t('Personel pour vous aidez à faire vos choix')}</h4>
        						
        					</div>
        				</div>
        			</div>
        			<div className="col-lg-4">
        				<div className="offer_item">
        					<img className="img-fluid" src={offer3} alt=""/>
        					<div className="offer_text">
        						<h4> {t("Acceuil pour vous guider dans nos magasin ")}<br/> {t("Votre satisfaction notre priorité")}</h4>
        	
        					</div>
        				</div>
        			</div>
        		</div>
        	</div>
        </section>


    </div>
  )
}

export default Offer