import { useEffect, useState } from "react";
import sanityClient from "../../lib/client";
import { Link } from "react-router-dom";
import NavBar from "../NavBar";
import Banner from "../Banner";
import Newproduct from "../NewProducts";
import Offer from "../Offer";
import FeatureArea from "../FeatureArea";
import HomeContact from "../HomeContact";
import Footer from "../Footer";

function Sacamains() {
  const [product, setProduct] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "product" && Category == "sac a main"]{
        image{
          asset ->{
            _id,
            url
          }
        },
		_id,
		price,
      }`
      )
      .then((data) => setProduct(data))
      .catch((err) => console.log(err));
  }, []);
  product.length = 3;
  console.log(product);
  return (
    <div>
      <NavBar />
      <Banner />
      <Newproduct />
      <Offer />
      <FeatureArea />
      <section className="home_gallery_area p_120">
        <div className="container">
          <div className="main_title">
            <h2>Nos recents Produits en stock</h2>
          </div>
          <div className="isotope_fillter">
            <ul className="gallery_filter list">
              <li className="active" data-filter="*">
                <Link to="/">Toutes les categories</Link>
              </li>
              <li data-filter=".brand">
                <Link to="/Valisedevoyage">Valises de Voyage</Link>
              </li>
              <li data-filter=".manipul">
                <Link to="/Chaussure">Chaussures</Link>
              </li>
              <li data-filter=".creative">
                <Link to="/Couvertdetable">Ustensile de Cuisine</Link>
              </li>
              <li data-filter=".design">
                <Link to="/Tissue">Tissus</Link>
              </li>
              <li data-filter=".print">
                <Link to="/Sacamains">Sac a Mains</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="gallery_f_inner row imageGallery1">
            {product.map((p) => (
              <div className="col-lg-4 col-md-4 col-sm-6 brand" key={p._id}>
                <div className="h_gallery_item">
                  <div className="g_img_item">
                    <img
                      className="img-fluid"
                      src={p.image.asset.url}
                      alt=""
                      style={{ height: "352px", width: "416px" }}
                    />
                  </div>
                  <div className="g_item_text"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <HomeContact />
      <Footer />
    </div>
  );
}

export default Sacamains;
