import './App.css';
import NavBar from './components/NavBar';
import Contact from './pages/contact';
import {Route,Routes} from 'react-router-dom'
import Welcome from './pages/Welcome';
import About from './pages/About';
import Tissue from './components/gallery/Tissue';
import Valisedevoyage from './components/gallery/Valisedevoyage';
import Chaussure from './components/gallery/Chaussure'
import Sacamains from './components/gallery/Sacamains'
import Couvertdetable from './components/gallery/Couvertdetable';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div className="App">
      <NavBar/>
      <Routes>
        <Route path='/'element={<Welcome/>}/>

        
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/About' element={<About/>}/>
    <Route path='/Tissue' element={<Tissue/>}/>
    <Route path='/Valisedevoyage' element={<Valisedevoyage/>}/>
    <Route path='/Chaussure' element={<Chaussure/>}/>
    <Route path='/Sacamains' element={<Sacamains/>}/>
    <Route path='/Couvertdetable' element={<Couvertdetable/>}/>


      </Routes>
      <ToastContainer/>
    </div>
  );
}

export default App;
