import {useRef}from 'react'
import emailjs from '@emailjs/browser';
import {AiOutlineClockCircle} from 'react-icons/ai'
import {FiPhoneCall} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import Footer from '../components/Footer';
import {toast} from 'react-toastify'
function Contact() {

    const form = useRef();
	  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_zlztt1c', 'template_f9cdcbr', form.current, '7fPQ_2lIsrdVKsSba')
        .then((result) => {
            console.log(result.text);
            toast.success("Message envoyé",{
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                hideProgressBar: false,
            });
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <>
 {/* <!--================Home Banner Area =================--> */}
        <section className="banner_area">
            <div className="banner_inner d-flex align-items-center">
				<div className="container">
					<div className="banner_content text-center">
						<div className="page_link">
							<Link to="/">Home</Link>
							<Link to="/Contact">Contact</Link>
						</div>
						<h2>Contact Us</h2>
					</div>
				</div>
            </div>
        </section>
        {/* <!--================End Home Banner Area =================--> */}
        
        {/* <!--================Contact Area =================--> */}
        <section className="contact_area p_120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="contact_info">
                            <div className="info_item">
                                <i className="lnr lnr-home"></i>
                                <h6>Bujumbura, BURUNDI</h6>
                                <p>Avenue de l'industrie No 16 </p>
                            </div>
                            <div className="info_item">
                                <i className="lnr lnr-phone-handset"></i>
                                <h6><FiPhoneCall/> : +257 22 24 60 55 <br/>  <div style={{marginLeft:'28px'}}>+257 76 48 07 64</div> </h6>
                                <p> <AiOutlineClockCircle/> Lundi - vendredi : 7h30 - 18h</p>
                                <p><AiOutlineClockCircle/> Samedi - dimanche : 8h - 14h45 </p>
                            </div>
                            <div className="info_item">
                                <i className="lnr lnr-envelope"></i>
                                <h6>tanganyikabc@gmail.com</h6>
                                <p>Send us your query anytime!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <form className="row contact_form"  method="post" id="contactForm" onSubmit={sendEmail} ref={form}>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="name" name="user_name" placeholder="Enter your name"/>
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" id="email" name="user_email" placeholder="Enter email address"/>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <textarea className="form-control" name="message" id="message" rows="1" placeholder="Enter Message"></textarea>
                                </div>
                            </div>
                            <div className="col-md-12 text-right">
                                <button type="submit" value="send" className="btn submit_btn">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        {/* <!--================Contact Area =================--> */}
        
<Footer/>

    </>
  )
}

export default Contact