import React from 'react'
import NavBar from '../components/NavBar'
import Banner from '../components/Banner'
import Offer from '../components/Offer'
import FeatureArea from '../components/FeatureArea'
import HomeContact from '../components/HomeContact'
import HomeGallerieArea from '../components/HomeGallerieArea'
import Footer from '../components/Footer'
import NewProduct from '../components/NewProducts'







function Welcome() {
  return (
    <div>
        <NavBar/>
          <Banner/>
          <NewProduct/>
          <Offer/>
          <FeatureArea/>
          <HomeGallerieArea/>
          <HomeContact/>
      <Footer/>
    </div>
  )
}

export default Welcome