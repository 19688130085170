import React from 'react'
import {useEffect,useState} from 'react'
import sanityClient from '../../lib/client'
import HomeContact from '../HomeContact';
import NavBar from '../NavBar';
import Banner from '../Banner';
import Offer from '../Offer';
import FeatureArea from '../FeatureArea';
import NewProduct from '../NewProducts';
import Footer from '../Footer';
import{Link} from 'react-router-dom'

function Tissue() {
  const [tissus,setTissus]=useState([]);
  useEffect(()=>{
    sanityClient.fetch(
      `*[_type == "product" && Category == "tissue"]{
        image{
          asset ->{
            _id,
            url
          }
        },
        name,
        _id,
		price,
      }`
    ).then((data)=> setTissus(data)).catch(err=>console.log(err))
  },[])
  tissus.length=3;
console.log(tissus)
  return (
    <div>
      <NavBar/>
          <Banner/>
          <NewProduct/>
          <Offer/>
          <FeatureArea/>
      <section className="home_gallery_area p_120">
        	<div className="container">
        		<div className="main_title">
        			<h2>Nos recents Produits en stock</h2>
        		</div>
        		<div className="isotope_fillter">
				<ul className="gallery_filter list">
						<li className="active" data-filter="*"><Link to='/'>Toutes les categories</Link></li>
						<li data-filter=".brand"><Link to="/Valisedevoyage">Valises de Voyage</Link></li>
						<li data-filter=".manipul"><Link to="/Chaussure">Chaussures</Link></li>
						<li data-filter=".creative"><Link to='/Couvertdetable'>Ustensile de Cuisine</Link></li>
						<li data-filter=".design"><Link to="/Tissue">Tissus</Link></li>
						<li data-filter=".print"><Link to="/Sacamains">Sac a Mains</Link></li>
					</ul>
        		</div>
        	</div>
        	<div className="container">
        		<div className="gallery_f_inner row imageGallery1">

               {tissus.map(t=>(

        			<div className="col-lg-4 col-md-4 col-sm-6 brand manipul design print" key={t._id}>
        				<div className="h_gallery_item">
        					<div className="g_img_item">
        						<img className="img-fluid" src={t.image.asset.url} alt="" style={{height:'352px' ,width:'416px'}}/>
        					<div className="g_item_text">
        						
        					</div>
        				</div>
        			</div>
              </div>
               ))}
        			
        		</div>
        	</div>
        </section>
        <HomeContact/>
		<Footer/>
    </div>
  )
}

export default Tissue