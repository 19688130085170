import React from "react";
import left from "../img/leftog2.jpg";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function HomeContact() {
  const { t } = useTranslation();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zlztt1c",
        "template_f9cdcbr",
        form.current,
        "7fPQ_2lIsrdVKsSba"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message envoyé", {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            hideProgressBar: false,
          });
        },
        (error) => {
          console.log(error.text);
          //   toast.error(error.text, {
          //     position: "top-right",
          //     autoClose: 5000,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "light",
          //     hideProgressBar: false,
          //   });
        }
      );
  };

  return (
    <div>
      <section
        className="home_contact_area"
        style={{ display: "flex", flexWrap: "wrap", gap: 0 }}
      >
        <div className="left_img" style={{ flex: "1 1 50%", padding: 0 }}>
          <img src={left} alt="" style={{ width: "100%", height: "100%" }} />
        </div>
        <div
          className="h_right_form"
          style={{
            flex: "1 1 50%",
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="h_form_inner"
            style={{ width: "100%", padding: "20px" }}
          >
            <h4>{t("Pour plus d'information")}</h4>
            <form
              className="row home_contact_form"
              method="post"
              id="contactForm"
              ref={form}
              onSubmit={sendEmail}
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="form-group col-md-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="user_name"
                  placeholder="Votre Nom"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="form-group col-md-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="user_email"
                  placeholder="Adresse E-mail"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="form-group col-md-12">
                <textarea
                  className="form-control"
                  name="message"
                  id="message"
                  rows="1"
                  placeholder="Votre message"
                  style={{ width: "100%" }}
                ></textarea>
              </div>
              <div className="form-group col-md-12">
                <button
                  type="submit"
                  value="send"
                  className="btn submit_btn form-control"
                  style={{ width: "100%" }}
                >
                  {t("Envoyez")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeContact;
