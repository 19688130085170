import React from "react";
import Carousel from "react-bootstrap/Carousel";
import banner from "../img/tbcimg/IMG_1 (17).jpg";
import banner1 from "../img/tbcimg/IMG_1 (49).jpg";
import banner2 from "../img/tbcimg/IMG_1 (63).jpg";
import banner3 from "../img/tbcimg/IMG_1 (64).jpg";
import banner4 from "../img/tbcimg/IMG_1 (65).jpg";
import banner5 from "../img/tbcimg/photo_2023-03-18_17-32-20 (5).jpg";

function Banner() {
  return (
    <div className="home_banner_area">
      <div className="banner_inner">
        <Carousel className="bannerf" style={{ marginTop: "2rem" }}>
          {/* <Carousel.Item interval={2000}>
	  <img
		className="d-block w-100 productimage"
		src={banner5}
		alt="First slide"
		style={{height:'50rem'}}
	  />
	</Carousel.Item> */}
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 productimage"
              src={banner}
              alt="First slide"
              style={{ height: "50rem" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 productimage"
              src={banner1}
              alt="Second slide"
              style={{ height: "50rem" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 productimage"
              src={banner2}
              alt="Third slide"
              style={{ height: "50rem" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 productimage"
              src={banner3}
              alt="Third slide"
              style={{ height: "50rem" }}
            />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img
              className="d-block w-100 productimage"
              src={banner4}
              alt="Third slide"
              style={{ height: "50rem" }}
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default Banner;
