import sanityClient from '@sanity/client';
import  ImageUrlBuilder  from '@sanity/image-url';

export default sanityClient({

    projectId:'e6m2240m',
    dataset:'production',
    apiVersion:'2021-10-21',
    useCdn:true
});

