import {useState,useEffect} from 'react'
import sanityClient from '../lib/client'
import {useTranslation} from 'react-i18next'
function FeatureArea() {
  const {t}=useTranslation()
	const [product,setProduct]=useState([]);
  useEffect(()=>{
    sanityClient.fetch(
      `*[_type == "banner"]{
        image{
          asset ->{
            _id,
            url
          }
        },
      }`
    ).then((data)=> setProduct(data)).catch(err=>console.log(err))
  },[])
  return (
    <>
         <section className="feature_area p_120">
        	<div className="container">	
        		<div className="main_title">
        			<h2>{t("Nos produits les mieux notés")}</h2>
        		
        		</div>
        		<div className="row feature_inner">
					{product.map(p=>(

        			<div className="col-lg-3 col-sm-6" key={p.image.asset._id}>
        				<div className="feature_item">
        					<img src={p.image.asset.url} style={{width:'250px',height:"258.66px"}} alt="" />
        				</div>
        			</div>
					))}
        		</div>
        	</div>
        </section>
       
    </>
  )
}

export default FeatureArea