import React from 'react'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import history from '../img/history.jpg'
import {useTranslation} from "react-i18next"

function About() {
	const {t}=useTranslation()
  return (
    <div>
        <NavBar/>
        <section className="about_area p_120">
        	<div className="container">
        		<div className="row about_inner">
        			<div className="col-lg-6">
        				<div className="about_img">
        					<img className="img-fluid" src={history} alt=""/>
        				</div>
        			</div>
        			<div className="col-lg-6">
        				<div className="about_right_text">
        					<h4>{t("Qui sommes nous ?")}</h4>
        					<p>{t("TANGANYIKA BUSINESS COMPANY S.A., T.B.C.s.a. en sigle, est une société de commerce général et d’import-export créé en 1997 à Dar –Es – Salaam par l’homme d’affaires burundais BARANKIRIZA NAHUM, et dont le siège social fut délocalisée au BURUNDI à Bujumbura en 2001.")}</p>
        					<p>{t("Généralement, T.B.C. s.a. importe des articles divers de consommation courante en provenance de l’Europe, l’Asie (principalement la Chine) ainsi que les Etats Unis. L’entreprise s’est spécialisée dans la commercialisation en grande quantité de biens  recherchés par toutes les catégories sociaux professionnels. Nos produits sont très compétitifs car non seulement on ne les trouve pas facilement sur le marché local, mais encore ils sont de très bonne qualité. Cela est ce qui fait notre avantage concurrentiel.")}</p>
                            <p>{t("Actuellement, T.B.C s.a. dispose de deux grands magasins dans la ville de Bujumbura, « TBC Center » ainsi qu’un grand complexe sur trois niveaux « TBC five STARS ».")}</p>
                            <p>{t("La société a aussi initié des projets de développement, notamment celui de la culture des cannes à sucre dans la plaine de la Ruzizi et de production et commercialisation de sucre, TANGANYIKA SUGAR INDUSTRIES, (T.S.I s.a). Son siège social est à Gihanga dans la province de Bubanza à 25 km au nord de Bujumbura")} </p>
        				</div>
        			</div>
        		</div>
        	</div>
        </section>
        <Footer/>
    </div>
  )
}

export default About