import {AiFillFacebook,AiOutlineInstagram,AiOutlineClockCircle} from 'react-icons/ai'
import {Link} from 'react-router-dom'
import {FiPhoneCall} from 'react-icons/fi'
import love from '../img/love.ico'
import Languageoption from './language-dropdown'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
function Footer() {
    const {t}=useTranslation()
const handleClick=(e)=>{
    i18next.changeLanguage(e.target.value)
}
  return (
    <div>
        <footer className="footer-area section_gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3  col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h6 className="footer_title">{t("A Propos de TBC")}</h6>
                            <p style={{color:'#777777'}}>{t("TANGANYIKA BUSINESS COMPANY S.A., T.B.C.s.a. en sigle, est une société de commerce général et d’import-export créé en 1997 à Dar –Es – Salaam par l’homme d’affaires burundais BARANKIRIZA NAHUM, et dont le siège social fut délocalisée au BURUNDI à Bujumbura en 2001. Généralement, T.B.C. s.a. importe des articles divers de consommation courante en provenance de l’Europe, l’Asie (principalement la Chine) ainsi que les Etats Unis.")}</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <h6 className="footer_title">Liens de Navigation</h6>
                            <div className="row">
                                <div className="col-4">
                                    <ul className="list">
                                        <li className='w-1'><Link to="/" className='footerlink'>ACCUEIL</Link></li>
                                        <li><Link to="/About" className='footerlink'>A PROPOS</Link></li>
                                        <li><Link to="/Contact" className='footerlink'>CONTACT</Link></li>
                                        
                                    </ul>
                                </div>								
                            </div>							
                        </div>
                    </div>							
                    <div className="col-lg-3 col-md-6 col-sm-6 ">
                        <div className="single-footer-widget">
                            <h6 className="footer_title">CONTACT</h6>
                            <div className="contact_info">
                            <div className="info_item">
                                <i className="lnr lnr-home"></i>
                                <h6>Bujumbura, BURUNDI</h6>
                                <p>Avenue de l'industrie No 16</p>
                            </div>
                            <div className="info_item">
                                <i className="lnr lnr-phone-handset"></i>
                                <h6><FiPhoneCall/> : +257 22 24 60 55 <br/>  <div style={{marginLeft:'28px'}}>+257 76 48 07 64</div> </h6>
                                <p> <AiOutlineClockCircle/>{t("Lundi - vendredi : 7h30 - 18h")}</p>
                                <p><AiOutlineClockCircle/>{t("Samedi - dimanche : 8h - 14h45")}  </p>
                            </div>
                            <div className="info_item">
                                <i className="lnr lnr-envelope"></i>
                                <h6>tanganyikabc@gmail.com</h6>
                            </div>
                        </div>		
                            <div id="mc_embed_signup">
                    
                                    <div className="mt-10 info"></div>
                                
                            </div>
                        </div>
                    </div>						
                </div>
                <div className="border_line"></div>
                <div className="row footer-bottom d-flex justify-content-between align-items-center">
                    <p className="col-lg-8 col-md-8 footer-text m-0" style={{color:'#777777'}}>
 Made with<img src={love} alt=''/> by  Tangu
</p>
                    <div className="col-lg-4 col-md-4 footer-social">
                        <a href="https://www.facebook.com/profile.php?id=100064320090041"><i className="fa fa-facebook"><AiFillFacebook/></i></a>
                        <a href="https://www.instagram.com/tbc_bdi/"><i className="fa fa-twitter"><AiOutlineInstagram/></i></a>
                        <Languageoption onChange={(e)=>{handleClick(e)}}/>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer