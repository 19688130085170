import {useState} from 'react'
import { Container,Nav,Navbar } from 'react-bootstrap'
import tbc from '../img/tbc_logo2.png'
import allo from '../img/allo.png'
import tbc2 from '../img/tbc2.png'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import Languageoption from './language-dropdown'

function NavBar() {
  const [isSelected, setisSelected] = useState(false)
  const onClick=()=>{
    setisSelected(true)
  }
  const {t}=useTranslation()
  const handleClick=(e)=>{
    i18next.changeLanguage(e.target.value)
}
  return (
    <>
 <Navbar  bg='light' expand='lg' fixed='top'> 
 <Container>
  <Link to='/'>
        <img
            src={allo}
            width="30"
              height="30"
              alt=''
              className='pr-10 allo'
            />
            <Navbar.Brand href='' className='pr-8' > 
            

            <img
              src={tbc}
              width="230"
              height="30"
              className="d-inline-block align-top"
              alt=''
            />  
            <img
              src={tbc2}
              width="30"
              height="30"
              className="pl-50"
              alt=''
            /> 

            </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className='justify-content-end'>
                <Nav>
                <Nav.Link><Link  to="/" className='navlink'  >{t('Accueil')}</Link></Nav.Link>
                <Nav.Link><Link to="/About" className={` ${isSelected ? 'navlink active-link':'navlink'}`} onClick={onClick} >{t('A propos')}</Link></Nav.Link>
                <Nav.Link><Link to="/contact" className='navlink'  >{t('contactez-nous')}</Link></Nav.Link>
                </Nav>
                <Languageoption onChange={(e)=>{handleClick(e)}}/>
                </Navbar.Collapse> 
        </Container>

    </Navbar>
    
    </>
  )
}

export default NavBar