const Languageoption= ({onChange}) =>{

return (
    <div >
        
    <select style={{color:'#003289', backgroundColor:'white',borderColor:"#003289"}} onChange={onChange}>
        <option value={'fr'}>fr</option>
        <option value={'en'}>en</option>

    </select>
    </div>
)

}

export default Languageoption