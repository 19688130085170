import { useEffect, useState } from "react";
import sanityClient from "../lib/client";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HomeGallerieArea() {
  const { t } = useTranslation();
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState("product");

  const handleChange = (category) => {
    sanityClient
      .fetch(
        `*[_type == "product" && Category == "${category}"]{
image{
asset ->{
_id,
url
}
},
_id,
}`
      )
      .then((data) => setProduct(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "product"]{
image{
asset ->{
_id,
url
}
},
_id,
}`
      )
      .then((data) => setProduct(data))
      .catch((err) => console.log(err));
  }, []);

  product.length = 20;

  return (
    <div>
      <section className="home_gallery_area p_120">
        <div className="container">
          <div className="main_title">
            <h2>{t("Nos recents Produits en stock")}</h2>
          </div>
          <div className="isotope_fillter">
            <ul className="gallery_filter list">
              <li className="active" data-filter="*">
                <Link to="/">Toutes les categories</Link>
              </li>
              <li data-filter=".brand">
                <button
                  className="link-button"
                  onClick={() => handleChange("valise")}
                >
                  Valises de Voyage
                </button>
              </li>
              <li data-filter=".manipul">
                <button
                  className="link-button"
                  onClick={() => handleChange("chaussure")}
                >
                  Chaussures
                </button>
              </li>
              <li data-filter=".creative">
                <button
                  className="link-button"
                  onClick={() => handleChange("couvert de table")}
                >
                  Ustensile de Cuisine
                </button>
              </li>
              <li data-filter=".design">
                <button
                  className="link-button"
                  onClick={() => handleChange("tissue")}
                >
                  Tissus
                </button>
              </li>
              <li data-filter=".print">
                <button
                  className="link-button"
                  onClick={() => handleChange("sac a main")}
                >
                  Sac a Mains
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="gallery_f_inner row imageGallery1">
            {product.map((p) => (
              <div className="col-lg-4 col-md-4 col-sm-6 brand" key={p._id}>
                <div className="h_gallery_item">
                  <div className="g_img_item">
                    <img
                      className="img-fluid"
                      src={p.image.asset.url}
                      alt=""
                      style={{ height: "352px", width: "416px" }}
                    />
                  </div>
                  <div className="g_item_text"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeGallerieArea;
